.myapes {
    min-width: 1150px;
    width: 50%;
    margin: auto;
    margin-top: 60px;
    display:flex;
    flex-direction: row;
  }

  .myapes-filter-sidebar-left {
    max-height: 1400px;
    width:200px;
    margin-right: 100px;
    position:relative;
    overflow-y: auto;
  }

  .myapes-content-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 850px;
  }

  .myapes-subheader {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 150px;
  }

  .myapes-subheader-button {
    background: #F1C83A;
    color: #333333;
    width: 180px;
    height: 40px;
    font-size: 20px;
    font-weight: 600;
    border-color: black;
    box-shadow: 5px 5px 0px black;
    align-self: center;
  }
  
  .myapes-subheader-button:hover {
    background-color: #EAE8DD;
    color: black;
  }

  .myapes-subheader-row1 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-height: 50px;
    height: 40%;
  }
  
  .myapes-subheader-row1 img {
    align-self: center;
    margin-right: auto;
    margin-left: 20px;
  }

  .myapes-subheader-row2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 10px;
  }

  .myapes-subheader-row2 span{
    /* margin-left: 20px;
    margin-top: 10px; */
  }

  .myapes-content {
    margin-top: 30px;
  }

  .card-custom {
    background: none;
    border: none;
    width: 250px; 
    cursor: pointer;
    margin-bottom: 10px;
  }

  .card-custom-clicked {
    border-width: 5px;
    border-color: black;
    border-radius: 5px 5px 5px 5px;
    background: none;
    margin-bottom: 10px;
  }

  .card-image-custom {
    border-radius: 5px 5px 5px 5px;
    width: 100%;
    height: 100%;
  }

  .card-body-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
  }

  .card-body-custom.card-body {
    height: 35px;
  }

  .card-title-custom {
    font-weight: 600;
    margin-top: 20px;
  }

  .card-col-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  flex-direction: row;
  
}

.loading-spinner {

  width: 3.2rem;
  height: 3.2rem;
  
}

.loading-text {
  font-size: 25px;
  margin: 0 0 0 20px;
}

.myapes-subheader-filter-button {
  display: none;
}

@media (max-width: 1200px) {
  .myapes {
  min-width: auto;
  width: 90%;
  margin: auto;
  margin-top: 20px;
  display:flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .myapes-filter-sidebar-left {
  max-height: 100%;
  width:200px;
  margin-right: 0;
  position:relative;
    overflow-y: auto;
  }

  
  .myapes-content-container {
  max-width: 750px;
  }
  
  .myapes-subheader-row1 img {
  margin-right: 0;
  margin-left: 10px;
  }
  
  .myapes-subheader-row2 span{
  margin-left: 10px;
  margin-top: 0;
  white-space:nowrap
  }

  .card-custom.card {
    width: 170px;
  }
  }

.myapes-filter-sidebar-right {
  display: none;   
 }

 .myapes-filter-sidebar-right-hidden {
   display: none;   
  }

 @media (max-width: 991px) {
   .myapes-filter-sidebar-left {
     display: none;
   }

   .myapes-filter-sidebar-right {
     display: revert;
     position:fixed;
     top:0px;
     right:0px;
     height: 100%;
     background: #D9D9D9;
     width:200px;
     padding-left: 10px;
     overflow-y: auto;
     transform: translateX(0%);
     transition: transform 0.3s ease-in;
   }

   .myapes-filter-sidebar-right-hidden {
     display: revert;
     position:fixed;
     top:0px;
     right:0px;
     height: 100%;
     background: #D9D9D9;
     width:200px;
     padding-left: 10px;
     overflow-y: auto;
     transform: translateX(100%);
     transition: transform 0.3s ease-out;
   }

     .myapes-subheader-filter-button {
       display: revert;
       width: 25px;
       height: 25px;
       border: none;
       background: none;
       padding: 0;
       align-self: center;
     }
   
     .myapes-subheader-filter-button > img{
       width: 20px;
       height: 20px;
       margin-left: 5px;
       margin-right: 10px;
     }

     .myapes-subheader-button {
       width: 140px;
       height: 35px;
       font-size: 1.125rem;
       margin-right: 20px;
     }

     .card-custom.card {
       width: 200px;
     }

     .card-custom-clicked.card {
       width: 200px;
     }

      .card-col-custom {
       max-width: 250px;
     } 

     .g-4 {
       justify-content: center;
     } 

 }

 @media (max-width: 528px) {


     .card-custom.card {
       width: 170px;
     }

     .card-custom-clicked.card {
       width: 170px;
     }

     .card-col-custom {
       max-width: 200px;
     } 

     .card-title-custom {
       font-weight: 600;
       margin-top: 20px;
       font-size: 1rem;
     }

     .myapes-subheader-button {
       margin-right: 10px;
       margin-left: 10px;
     }
 }

 @media (max-width: 420px) {
   .myapes-subheader-button {
     font-size: 1rem;

   }

   .myapes-subheader-filter-button > img{
     width: 16px;
     height: 16px;
     margin-left: 0;
     margin-right: 10px;
   }

   .card-col-custom {
     width: 50%;
   }
   
}

@media (max-width: 380px) {
 .myapes-subheader {
   margin-left: -15px;
 }
}
  