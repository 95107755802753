.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html,
body {
  height: 100%;
}

body {
  font-family: "Exo", sans-serif;
  background: #d0b49f;
  color: #333333;
  font-weight: 500;
}

.navbar-brand {
  font-size: 40px;
  margin-left: -5%;
  margin-right: 5%;
  color: #333333;
  font-weight: 800;
}

.navbar-info {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
}

.navbar-info-button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
}

.navbar-APEin-icon {
  margin-right: 30px;
  cursor: pointer;
}

.navbar-APEin-icon img {
  height: 35px;
  width: 35px;
  margin-top: -5px;
  margin-right: 10px;
}

.navbar-APEin-icon img:hover {
  height: 40px;
  width: 40px;
}

.navbar-link {
  font-size: 20px;
  color: #333333;
  font-weight: 600;
  margin-right: 20px;
  white-space: normal;
}

.navbar-link:hover {
  color: #000000;
  text-decoration: underline;
}

.navbar-link.active {
  text-decoration: underline !important;
  color: #333333 !important;
}

.color-button {
  background: #f1c83a;
  color: #333333;
  width: 180px;
  height: 40px;
  font-size: 20px;
  font-weight: 600;
  border-color: black;
  box-shadow: 5px 5px 0px black;
}

.color-button:hover {
  background-color: #eae8dd;
  color: black;
}

.viewall-button {
  border-radius: 30px 30px 30px 30px;
  border-color: white;
  color: white;
  background-color: black;
  width: 130px;
  height: 50px;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  z-index: 2;

  list-style-type: none;
  margin: 5px 0;
  padding: 0;

  border: 1px solid grey;
  width: 150px;
  height: 145px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #d9d9d9;
}

.menu > li {
  margin-top: 5px;
  background-color: #d9d9d9;
}

.menu > li:hover {
  background-color: #eae8dd;
}

.menu > li > button {
  width: 100%;
  height: 100%;
  text-align: center;

  background: none;
  color: inherit;
  border: none;
  padding: 5px;
  margin: 0;
  font: inherit;
  cursor: pointer;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler {
  border: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}

.navbar-nav {
  align-items: center;
}
