.info-icon-container {
      align-self: center;
      margin-right: auto; 
      background: none;
      border: none;
}

/* Styles for screens with a width of less than 420 pixels */
 @media all and (max-width: 528px) {

      .info-icon-container > img{
            width: 20px;
            height: 20px;
      }
} 