.smr-checkbox {
  width: 180px;
  align-self: center;
  flex-shrink: 0;
  margin-left: auto;
}

.smr-checkbox-card-clicked {
  width: 180px;
  align-self: center;
  flex-shrink: 0;
}

.smr-checkbox-input {
  margin-left: 10px;
  accent-color: black;
}

/* Styles for screens with a width of less than 420 pixels */
/* @media all and (max-width: 420px) {
.smr-checkbox {
  display: none;
}
}  */
