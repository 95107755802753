.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.home-stats {
  width: 100%;
  max-width: 1250px;
  margin-bottom: 20px;
}

.home-stats-title {
  font-size: 24px;
  font-weight: 700;
  text-align: start;
}

.home-stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
}

.last-row {
  grid-column: span 3;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 20px;
}

.banner {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
}

.banner-label {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.banner-number {
  font-size: 20px;
  font-weight: 700;
}

@media screen and (max-width: 768px) {
  .home-stats-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }

  .last-row {
    grid-column: span 2;
  }

  .banner {
    padding: 10px;
  }

  .home-stats-title {
    font-size: 20px;
  }

  .home-market-activity {
    margin-top: 30px;
    }
    
    .home-market-activity-header {
    flex-wrap: wrap;
    margin-bottom: 10px;
    }
    
    .home-market-activity-filters {
    width: 100%;
    margin-bottom: 20px;
    }
    
    .home-market-activity-filters > label {
    display: block;
    margin-bottom: 5px;
    }
    
    .home-market-activity-filters > button {
    width: 100%;
    margin: 0 0 5px;
    }
}


.home-market-activity {
  width: 100%;
  max-width: 1250px;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  }
  
  .home-market-activity-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  }
  
  .home-market-activity-filters {
  display: flex;
  align-items: center;
  overflow-x: auto;
  }
  
  .home-market-activity-filters > label {
  font-weight: 700;
  font-size: 16px;
  margin-right: 10px;
  }
  
  .home-market-activity-filters > button {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  margin-right: 5px;
  background: #EAE8DD;
  color: black;
  border:none;
  border-radius: 5px;
  }
  
  .home-market-activity-filters > button:hover {
  background: #F1C83A;
  }
  
  .home-market-activity-filters > button.active {
  background: #F1C83A;
  }
