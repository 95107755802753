.filterList {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    padding-left: 0;
  }
  
  .filterItem {
    background: none;
    color: inherit;
  }
  
  .filterItem > button{
    min-width: 100%; 
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 10px;
  }

  .filter-item-ape-number {
    font-size: 16px;
    font-weight: 600;
    color: black;
    text-align: left;
    white-space:nowrap;
    margin: 0px;
  }

  .filter-item-ape-number-input {
    width: 100%;
    background-color: #D9D9D9;
    border: none;
    font-size: 14px;
    height: 2rem;
    text-align: center;
    margin-bottom: 10px;
  }

  .filter-item-ape-number-button-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .filter-item-ape-number-button-wrapper > button {
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    margin-left: 5%;
  }
  
  .filterSubItem {
    background: none;
    color: inherit;
    font-size: 12px;
    display: flex;
    padding: 2px;
  }
  
  .filterSubItem > input[type="checkbox"] {
    accent-color: black;
  }
  
  .filterListSubItem {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
  }

  .filterClearAllButton{
    width: 100px;
    background: none;
    border: none;
    height: 26px;
    font-size: 12px;
  }

  .filterHeaderLabel{
    font-size: 18px;
    font-weight: 700;
    height: 26px;
  }

  .filterHeaderContainer{
    display: flex;
    margin-left: 0;
    align-items: center;
    padding-bottom: 1.3rem;
  }

  .filterItemButtonContainer{
    display: flex;
    margin-left: 0;
    align-items: center;
  }

  .filterItemButtonContainer > button {
    background: none;
    border: none;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    padding: 10px;
    padding-left: 0;
  }

  .filterClearButtonItem {
    font-size: 12px !important;
    font-weight: 500 !important;
  }

  .filterExpandIndicator {
    position: absolute;
    right: 10px;
  }

  .filter-header-close-button{
    width: 50px;
    height: 40px;
    margin-left: auto;
    border: none;
    background: none;
    font-size: 18px;
    font-weight: 700;
    display: none;
  }

  @media (max-width: 991px) {
    .filter-header-close-button {
      display: revert;
    }

    .filter-item-ape-number-input {
      background-color: white;
    }
  }