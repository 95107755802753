.pagination-nav {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pagination > li:hover,
.pagination > li:focus {
  background-color: #000000;
}

.pagination > li {
  color: #000000;
  border-radius: 0px;
  border: 2px solid black;
  box-shadow: 5px 5px 0px black;
}

.pagination > li > a {
  background-color: #d9d9d9;
  color: #000000;
  border-radius: 0px !important;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  color: black;
  background-color: #d9d9d9;
  border: solid 1px #000000 !important;
  outline: none !important;
  box-shadow: none;
}

.pagination > .active > a {
  color: rgb(0, 0, 0);
  background-color: #f1c83a !important;
  border: solid 1px #000000 !important;
}

.pagination > .active > a:hover {
  background-color: #f1c83a !important;
  border: solid 1px #000000;
}

.page-link {
  cursor: pointer;
}

/* Styles for screens with a width of less than 687 pixels */
@media all and (max-width: 687px) {
  .page-link {
    padding: 0;
    font-size: 0.9rem;
    min-width: 25px;
    min-height: 20px;
  }
}
