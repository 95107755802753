.button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 255px;
    width: 28%;
    height: 100%;
    background: #d9d9d9;
  }
  
  .left-button,
  .right-button {
    padding: 5px;
    max-width: 110px;
    width: 50%;
    height: 80%;
    border-radius: 5px;
    border: none;
    font-size: 1.25rem;
    font-weight: 700;
    background: none;
    transition: background 0.3s ease-in-out;
    margin: 5px;
    white-space:nowrap

  }

  .active-button {
    background: #f1c83a;
  }

  @media (max-width: 991px) {

    .button-container {
      min-width: 205px;
    }

    .left-button,
  .right-button {
    font-size: 1.25rem;
  }
}

@media (max-width: 528px) {

  .button-container {
    min-width: 180px;
    height: 90%;
    align-self: center;
  }

  .left-button,
  .right-button {
    font-size: 1rem;
  }
}

@media (max-width: 420px) {

}

  /* Styles for screens with a width of less than 420 pixels */
/* @media all and (max-width: 420px) {
  .button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    height: 25px;
    background: #d9d9d9;
  }

  .left-button,
  .right-button {
    padding: 2px;
    width: 80px;
    height: 20px;
    border-radius: 5px;
    border: none;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    align-items: center;
    background: none;
    transition: background 0.3s ease-in-out;

  }

  .left-button {
    margin-right: 5px;
    margin-left: 2.5px;
  }
  
  .right-button {
    margin-right: 2.5px;
    margin-left: 5px;
  }

  .active-button {
    background: #f1c83a;
  }
} */