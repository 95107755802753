.dialog-container{
    display:flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 1;
}

.dialog-rectangle {
    display: flex;
    background-color: #d9d9d9;
    width: 250px;
    height: 150px;
    border-radius: 5px;
    flex-direction: column;
    justify-content: space-between;
}

.dialog-text {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.dialog-button {
    margin: 0px 0px 10px 0px;
    background: #F1C83A;
    color: #333333;
    border-color: black;
    box-shadow: 5px 5px 0px black;
    align-self: center;
}

.dialog-button:hover {
    background-color: #EAE8DD;
    color: black;
  }

