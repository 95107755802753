.activity-list-item-img {
    width: 90px;
    height: 90px;
    border-radius: 5px;
  }
  
  .activity-list-item-label {
    margin-left: 10px;
    width: 130px;
    display: inline-block;
    font-weight: 700;
  }

  .activity-list-price {
    white-space:nowrap;
  }
  
  .activity-list-price-img {
    width: 20px;
    height: 20px;
  }
  
  .activity-list-price-label {
    margin-left: 5px;
  }

  .activity-list-date-label {
    white-space:nowrap;
  }
  
  .activity-list-tx-img {
    width: 40px;
    height: 40px;
  }
  
  .list-item-row {
    border-top: 1px solid #A87A58;
    border-bottom: 1px solid #A87A58;
  }
  
  tr.list-item-row td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 10px;
  }
  
  tbody:before {
    line-height: 2em;
    content: "\200C";
  }
  
  /* styles for small screens */
  @media only screen and (max-width: 768px) {
    .activity-list-item-img {
      width: 50px;
      height: 50px;
    }
  
    .activity-list-item-label {
      margin-left: 5px;
      width: 100px;
    }
  
    .activity-list-price-label {
      margin-left: 5px;
    }
  
  }
  
  /* styles for extra small screens */
  @media only screen and (max-width: 480px) {
    .activity-list-item-img {
      width: 40px;
      height: 40px;
    }
  
    .activity-list-item-label {
      margin-left: 5px;
      width: 80px;
      font-size: 12px;
    }
  
    .activity-list-price-label {
      margin-left: 5px;
    }
  }