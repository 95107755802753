.modal-custom .modal-content {
  background-color: #d0b49f;
  border: none;
  border-radius: 0px;
}

.modal {
  --bs-modal-width: 550px;
}

.modal-title {
  font-size: 18px;
  font-weight: 700;
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  border-top: none;
  justify-content: start;
}

.modal-close-button {
  width: 50px;
  height: 40px;
  margin-left: auto;
  border: none;
  background: none;
  font-size: 18px;
  font-weight: 700;
}

.input-increment-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -13px;
}

.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  padding: 3px;
  cursor: pointer;
  caret-color: transparent;
  width: 12px;
  height: 12px;
}

.up {
  transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.modal-balance-info {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: -1rem;
  display: flex;
  align-items: center;
}

.modal-apedao-icon {
  width: 16px;
  height: 16px;
}
